<template>
  <video
    v-if="src"
    id="my-video"
    class="video-js vjs-default-skin my-video"
    autoplay
    controls
    :muted="false"
    preload="auto"
  >
    <source :src="src" :type="type" style="width: 100%; height: 100%" />
  </video>
</template>

<script>
import videojs from 'video.js/dist/video.min';
import 'video.js/dist/video-js.min.css';
import store from "@/store";
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  computed,
  getCurrentInstance,
} from 'vue';

export default defineComponent({
  name: 'VideoItem',

  props: {
    // 视频地址
    currentVideo: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const instance = getCurrentInstance();
    const _this = instance.appContext.config.globalProperties;
    // 监听视频地址变化
    // watch(
    //   () => props.currentVideo,
    //   (n) => {
    //     changeVideoSource(n);
    //   }
    // );

    // 在onMounted阶段进行初始化
    onMounted(() => {
      console.log(props.currentVideo)
      initVideo();
    });

    // 在onBeforeUnmount阶段释放资源
    onBeforeUnmount(() => {
      disposeVideo();
    });
    const src = computed(() => {
      var url = props.currentVideo.m3u8_url
        ? props.currentVideo.m3u8_url
        : props.currentVideo.url;
      return store.getVideo(url);
    });
    const type = computed(() => {
      return props.currentVideo.m3u8_url
        ? 'application/x-mpegURL'
        : 'video/mp4';
    });
    // 初始化视频
    function initVideo() {
      if (!props.currentVideo) return;
      videojs(
        'my-video',
        {
          bigPlayButton: false,
          textTrackDisplay: false,
          posterImage: false,
          errorDisplay: false,
          controlBar: true,
        },
        () => {
          const myPlayer = videojs('my-video');
          myPlayer.play();
          myPlayer.on('error', () => {
            // this.pause();
            _this.$message({
              message: '视频无法播放,建议删除!',
              type: 'error',
            });
          });
        }
      );
    }
    // 视频地址发生变化时的处理
    // function changeVideoSource(url) {
    //   if (!url) return;
    //   const myPlayer = videojs('my-video');
    //   console.log(type, src);
    //   myPlayer.src([
    //     {
    //       type: type.value,
    //       src: src.value,
    //     },
    //   ]);
    //   myPlayer.play();
    // }
    function disposeVideo() {
      const myPlayer = videojs('my-video');
      myPlayer.dispose();
    }
    return {
      src,
      type,
    };
  },
});
</script>
<style scoped>
.video-js {
  /* max-width: 300px;
  height: 500px; */
}
video {
  /* object-fit: cover; */
}
</style>
