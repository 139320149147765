<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">视频ID：</span>
        <el-input
          class="width_150"
          v-model="search.id"
          placeholder="请输入ID"
          size="medium"
          clearable
        ></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">视频标题：</span>
        <el-input
          class="width_250"
          v-model="search.title"
          placeholder="请输入视频标题"
          size="medium"
          clearable
        ></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">账号：</span>
        <el-input
          v-model="search.username"
          class="width_200"
          placeholder="请输入账号"
          size="medium"
          clearable
        ></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">是否免费</span>
        <el-select v-model="search.is_free" class="width_80" placeholder="全部" size="medium">
          <el-option label="全部" value="-1"></el-option>
          <el-option label="收费" value="0"></el-option>
          <el-option label="免费" value="1"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <span class="search-box-title">分类：</span>
        <el-select
          v-model="search.type_id"
          filterable
          placeholder="全部"
          size="medium"
          clearable
        >
          <el-option key="-1" label="全部" value="0"></el-option>
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <span class="search-box-title">状态：</span>
        <el-select
          v-model="search.status"
          filterable
          class="width_120"
          placeholder="全部"
          size="medium"
          clearable
        >
          <el-option key="-1" label="全部" value="-1"></el-option>
          <el-option key="0" label="待审核" value="0"></el-option>
          <el-option key="1" label="上架" value="1"></el-option>
          <el-option key="2" label="审核不通过" value="2"></el-option>
          <el-option key="3" label="下架" value="3"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <span class="search-box-title">时间：</span>
        <el-date-picker
          v-model="search.searchDate"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="YYYY-MM-DD"
        ></el-date-picker>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList()"
          >查询</el-button
        >
      </div>
<!--      <div class="search-box">-->
<!--        <el-button type="primary" size="medium" @click="goToUploader()"-->
<!--          >视频上传</el-button-->
<!--        >-->
<!--      </div>-->
      <div class="search-box">
        <el-button type="primary" size="medium" @click="del()"
          ><i class="el-icon-delete"></i>删除</el-button
        >
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="showVerify()"
          >批量审核</el-button
        >
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="showVerify(1)">批量下架</el-button>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="showFufei()">批量付费设置</el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      @selection-change="handleSelectionChange"
      :header-cell-style="{ background: '#F7F8FA' }"
    >
      <!--      type="selection"
        :selectable="handleIsSelect"-->
      <el-table-column
        type="selection"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="id"
        label="ID"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="username"
        label="账号"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="title"
        label="视频标题"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="pic_url"
        label="封面图"
        align="center"
        :show-overflow-tooltip="true"
      >
        <template #default="scope">
          <ximg
            v-if="scope.row.pic_url != ''"
            :src="scope.row.pic_url"
            :width="30"
            :height="30"
            @click="showVideo(scope.row)"
            alt="点击查看视频"
            title="点击查看视频"
          />
        </template>
      </el-table-column>
<!--      <el-table-column-->
<!--        prop="url"-->
<!--        label="视频地址"-->
<!--        align="center"-->
<!--        :show-overflow-tooltip="true"-->
<!--      >-->
<!--        <template #default="scope">-->
<!--          <button @click="showVideo(scope.row)">点击查看</button>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column
        prop="size"
        label="大小/时长"
        align="center"
        :show-overflow-tooltip="true"
      >
        <template #default="scope">
          <span
            >{{ getSize(scope.row.size) }}/{{
              getDuration(scope.row.duration)
            }}</span
          >
        </template>
      </el-table-column>
      <!--      <el-table-column prop="duration" label="时长" align="center" :show-overflow-tooltip="true">-->
      <!--        <template #default="scope">-->
      <!--          <span>{{getDuration(scope.row.duration)}}</span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column
        prop="addtime"
        label="添加时间"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>
<!--      <el-table-column-->
<!--        prop="m3u8_tag"-->
<!--        label="源"-->
<!--        align="center"-->
<!--        :show-overflow-tooltip="true"-->
<!--      >-->
<!--        <template #default="scope">-->
<!--          <span>{{ getTag(scope.row.m3u8_tag) }}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column
        prop="types"
        label="分类"
        align="center"
        :show-overflow-tooltip="true"
      >
        <template #default="scope">
          <span v-for="item of scope.row.types" :key="item.title"
            >{{ item.title }},</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="watch_count"
        label="观看次数"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="goods_count"
        label="点赞次数"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="is_fee" label="是否免费" align="center" :show-overflow-tooltip="true">
        <template v-slot="scope">
          <div :style="{color: checkIsFee(scope.row) == 1 ? '#33C933' : '#F33333' }">{{checkIsFee(scope.row) == 1 ? "免费" : "付费"}}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        align="center"
        :show-overflow-tooltip="true"
      >
        <template v-slot="scope">
          <div :style="{ color: '#faa755' }" v-if="scope.row.status == 0">
            审核中
          </div>
          <div :style="{ color: '#33C933' }" v-if="scope.row.status == 1">
            已上架
          </div>
          <div :style="{ color: '#d71345' }" v-if="scope.row.status == 2">
            审核不通过
          </div>
          <div :style="{ color: '#8a8c8e' }" v-if="scope.row.status == 3">
            已下架
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px" align="center">
        <template #default="scope">
          <span
            class="operation"
            v-if="scope.row.status == 0"
            @click="showVideo(scope.row)"
            >审核</span
          >
          <span class="operation" @click="showDialog(scope.row)">编辑</span>
          <!--          <span class="operation" @click="goToUploader(scope.row)">编辑</span>-->
          <span class="operation" @click="goToReport(scope.row.id)"
            >举报内容</span
          >
          <!--          <router-link class="operation" to="short_video/report?id=1">举报内容</router-link>-->
          <span class="operation" @click="goToComment(scope.row.id)"
            >评论列表</span
          >
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />

    <el-dialog
      :title="verifyTitle"
      v-model="isShowVideo"
      width="600px"
      height="500px"
      top="10vh"
      :close-on-click-modal="false"
      :before-close="closeShowVideo"
    >
      <el-row>
        <el-col :span="4">视频标题:</el-col>
        <el-col :span="20">
          <div>{{ verifyArr.title }}</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">账号:</el-col>
        <el-col :span="20">
          <span>{{ verifyArr.username }}</span> 上传时间:
          <span>{{ verifyArr.addtime }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">视频:</el-col>
        <el-col :span="20">
          <!-- <div
            id="verify_video"
            style="max-width: 300px; max-height: 500px"
          ></div> -->
          <VideoPlayer
            style="max-width: 300px; max-height: 500px"
            v-if="isShowVideo"
            :currentVideo="currentVideo"
          ></VideoPlayer>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">标签:</el-col>
        <el-col :span="20">
          <el-tag v-for="item in verifyArr.types" :key="item">{{
            item.title
          }}</el-tag>
        </el-col>
      </el-row>
      <el-row v-show="verifyArr.status > 1">
        <el-col :span="4">原因:</el-col>
        <el-col :span="20">
          <span>{{ verifyArr.reason }}</span>
        </el-col>
      </el-row>
      <el-row v-if="verifyArr.status == 0">
        <el-col :span="4">审核:</el-col>
        <el-col :span="20">
          <el-radio
            v-model="verifyArr.radio"
            @change="selectStatus(1)"
            label="1"
            >通过</el-radio
          >
          <el-radio
            v-model="verifyArr.radio"
            @change="selectStatus(2)"
            label="2"
            >不通过</el-radio
          >
          <div v-show="verifyArr.showReason">
            <div>
              <el-select v-model="verifyArr.selectReason" style="width: 100%">
                <el-option
                  v-for="op in reasonOption"
                  :key="op.value"
                  :value="op.value"
                  :label="op.value"
                ></el-option>
              </el-select>
            </div>
            <div v-show="verifyArr.selectReason == '其他'">
              <el-input
                placeholder="请输入原因"
                size="small"
                v-model="verifyArr.reason"
              ></el-input>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20" align="center">
          <el-button @click="closeShowVideo">关闭</el-button>
          <el-button v-if="verifyArr.status == 0" @click="verify"
            >确定</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog
      :title="dialogTitle"
      v-model="dialogAdd"
      width="700px"
      top="10vh"
      :close-on-click-modal="false"
    >
      <el-row>
        <el-col :span="4">标题:</el-col>
        <el-col :span="20"
          ><el-input
            type="textarea"
            v-model="dialogArr.title"
            :autosize="{ minRows: 3, maxRows: 4 }"
            placeholder="视频标题"
            size="small"
          ></el-input
        ></el-col>
      </el-row>
      <el-row>
        <el-col :span="4">账号:</el-col>
        <el-col :span="20">
          <el-input
            v-model="dialogArr.username"
            placeholder="账号"
            size="small"
          ></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"> </el-col>
        <el-col :span="8">
          <VideoPlayer
            style="max-width: 100%; max-height: 300px"
            v-if="videoForm.showVideoPath != '' && !videoFlag && dialogAdd"
            :currentVideo="currentVideo"
          ></VideoPlayer>
          <div class="upload-video" v-else>
            <i
              v-if="videoForm.showVideoPath == '' && !videoFlag"
              class="el-icon-plus avatar-uploader-icon"
              >预览区域</i
            >
            <el-progress
              v-if="videoFlag == true"
              type="circle"
              v-bind:percentage="videoUploadPercent"
              style="margin-top: 7px"
            ></el-progress>
            <!--            </el-upload>-->
          </div>
        </el-col>
        <el-col :span="6">
          <div>时长:{{ getDuration(videoForm.duration) }}</div>
          <div>大小:{{ getSize(videoForm.size) }}</div>
        </el-col>
        <el-col :span="6">
          <div>封面图</div>
          <div><ximg :src="videoForm.img_url" alt="" :width="150" ></ximg></div>
        </el-col>
      </el-row>
      <el-row v-if="dialogTitle == '视频添加'">
        <el-col :span="4">审核:</el-col>
        <el-col :span="20">
          <el-checkbox v-model="dialogArr.verify">通过</el-checkbox>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">点赞次数:</el-col>
        <el-col :span="20">
          <el-input
            type="number"
            v-model="dialogArr.goods_count"
            placeholder="点赞次数"
            size="small"
          ></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">观看次数:</el-col>
        <el-col :span="20">
          <el-input
            type="number"
            v-model="dialogArr.watch_count"
            placeholder="观看次数"
            size="small"
          ></el-input>
        </el-col>
      </el-row>
      <el-row  v-if="title !== '视频添加'">
      <el-col :span="4">付费设置：</el-col>
      <el-col :span="20">
        <el-form label-width="80px" aria-label="left">
          <el-form-item label="是否收费">
            <el-switch class="switch" :active-value=0 :inactive-value=1 v-model="dialogArr.is_fee"></el-switch>
          </el-form-item>
          <el-form-item label="购买价格" v-show="dialogArr.is_fee == 0">
            <el-input type="number" style="width:100px" min="0" v-model="dialogArr.buy_config.price" placeholder="请输入购买金额"></el-input>
          </el-form-item>
          <el-form-item label="试看时间"  v-show="dialogArr.is_fee == 0">
            <el-time-picker value-format="HH:mm:ss" style="width: 120px" size="mini"  v-model="dialogArr.buy_config.try_btime"></el-time-picker>-
            <el-time-picker value-format="HH:mm:ss" style="width: 120px" size="mini"  v-model="dialogArr.buy_config.try_etime"></el-time-picker>
          </el-form-item>
<!--          <el-form-item label="VIP"  v-show="dialogArr.is_fee == 0">-->
<!--            <el-select v-model="dialogArr.buy_config.free_vips" multiple="multiple" clearable="clearable">-->
<!--              <el-option v-for="item of vipList" :key="item" :label="item.label" :value="item.value"></el-option>-->
<!--            </el-select><i>收费开启时,选中的vip免费观看</i>-->
<!--          </el-form-item>-->
        </el-form>
      </el-col>
    </el-row>

      <el-row>
        <el-col :span="4">标签:</el-col>
        <el-col :span="20">
          <el-select v-model="dialogArr.types" multiple placeholder="请选择">
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20" align="center">
          <el-button type="primary" @click="dialogAdd = false">取消</el-button>
          <el-button
            type="primary"
            @click="add"
            v-if="dialogTitle == '视频添加'"
            >添加</el-button
          >
          <el-button type="primary" @click="edit" v-else>确认修改</el-button>
        </el-col>
      </el-row>
    </el-dialog>



    <el-dialog
        title="批量付费设置"
        v-model="dialogFufei"
        width="700px"
        top="10vh"
        :close-on-click-modal="false"
    >
      <el-row >
        <el-col :span="4">付费设置：</el-col>
        <el-col :span="20">
          <el-form label-width="80px" aria-label="left">
            <el-form-item label="是否收费">
              <el-switch class="switch" :active-value=0 :inactive-value=1 v-model="fufeiArr.is_fee"></el-switch>
            </el-form-item>
            <el-form-item label="购买价格" v-show="fufeiArr.is_fee == 0">
              <el-input type="number" style="width:100px" min="0" v-model="fufeiArr.price" placeholder="请输入购买金额"></el-input>
            </el-form-item>
            <el-form-item label="试看时间"  v-show="fufeiArr.is_fee == 0">
              <el-time-picker value-format="HH:mm:ss" style="width: 120px" size="mini" v-model="fufeiArr.try_btime"></el-time-picker>-
              <el-time-picker value-format="HH:mm:ss" style="width: 120px" size="mini"  v-model="fufeiArr.try_etime"></el-time-picker>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4"></el-col>
        <el-col :span="20" align="center">
          <el-button type="primary" @click="dialogFufei = false">取消</el-button>
          <el-button type="primary" @click="saveFufei" >保存</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog
      :title="multiVerifyTitle"
      v-model="dialogMultiVerify"
      :close-on-click-modal="false"
    >
      <el-row>
        <el-col :span="4">视频数量:</el-col>
        <el-col :span="20">{{ multiVerify.num }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="4">视频ID:</el-col>
        <el-col :span="20">{{ multiVerify.ids.join(',') }}</el-col>
      </el-row>
      <el-row v-if="multiVerifyTitle == '批量审核'">
        <el-col :span="4">审核结果:</el-col>
        <el-col :span="20">
          <el-radio
            v-model="multiVerify.radio"
            @click="multiVerify.showReason = false"
            label="1"
            >通过</el-radio
          >
          <el-radio
            v-model="multiVerify.radio"
            @click="multiVerify.showReason = true"
            label="2"
            >不通过</el-radio
          >
        </el-col>
      </el-row>
      <el-row v-show="multiVerify.showReason">
        <el-col :span="4">原因:</el-col>
        <el-col :span="20">
          <div>
            <el-select
              v-if="multiVerifyTitle == '批量审核'"
              v-model="multiVerify.selectReason"
              style="width: 100%"
            >
              <el-option
                v-for="op in reasonOption"
                :key="op.value"
                :value="op.value"
                :label="op.value"
              ></el-option>
            </el-select>
            <el-select
              v-else
              v-model="multiVerify.selectReason"
              style="width: 100%"
            >
              <el-option
                v-for="op in reasonOption2"
                :key="op.value"
                :value="op.value"
                :label="op.value"
              ></el-option>
            </el-select>
          </div>
          <div v-show="multiVerify.selectReason == '其他'">
            <el-input
              placeholder="请输入原因"
              size="small"
              v-model="multiVerify.reason"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10"></el-col>
        <el-col :span="14">
          <el-button @click="dialogMultiVerify = false">取消</el-button>
          <el-button type="primary" @click="mutilVerify()">确定</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col></el-col>
        <el-col></el-col>
      </el-row>
    </el-dialog>

    <el-dialog
        title="部分视频付费设置失败"
        v-model="dialogFufeiTips"
        width="700px"
        top="10vh"
        :close-on-click-modal="false"
    >
      <el-row >
        <el-col :span="14">视频</el-col>
        <el-col :span="10">失败原因</el-col>
      </el-row>
      <el-row v-for="item in errList" :key="item">
        <el-col :span="14">{{item.title}}</el-col>
        <el-col :span="10">{{ item.errMsg }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="10"></el-col>
        <el-col :span="14">
          <el-button type="primary" @click="dialogFufeiTips = false">关闭</el-button>
        </el-col>
      </el-row>
    </el-dialog>


    <video src="" id="hidden_video" style="position: relative; left: -9999px">
      您的浏览器不支持视频
    </video>
  </div>
</template>

<script>
import page from '@/components/page';
import VideoPlayer from '@/components/videoPlayer';
import { list as tagList } from '@/api/system/tagList';
import { list, add, del, verify, edit } from '@/api/short_video/short_video.js';
import httpClient from "@/config/httpClient";
// import {upload} from "@/api/common/common"; // uploadVideo
// import SparkMD5 from "spark-md5"
// import axios from "axios";
// import 'video.js/dist/video-js.css';
// import videojs from 'video.js';
// import 'videojs-contrib-hls'
export default {
  name: 'shortVideoList',
  components: {
    page,
    VideoPlayer,
  },
  data() {
    return {
      dialogFufeiTips:false,
      errList:[],
      multiVerify: {
        xiajia: false,
        radio: 0,
        num: 0,
        showReason: false,
        selectReason: '',
        reason: '',
        ids: [],
      },
      pager: { total: 0, page: 1, rows: 20 },
      search: {
        //搜索
        id: '', //id
        username: '', //账号
        type_id: null, //分类
        searchDate: [], //时间
        checked: 0, //是否精确搜索
        status: null,
        is_free:"-1",
        m3u8_tag: -1,
      },
      tableData: [], //数据
      typeList: [],
      dialogAdd: false, //添加弹窗
      fullscreenLoading: false,
      dialogTitle: '添加视频',
      isShowVideo: false, // 查看视频
      videoUrl: '', // 视频地址
      verifyTitle: '视频审核',
      verifyArr: {
        id: '', // 视频ID
        url: '', // 视频实际地址
        username: '', // 账号
        types: [], //视频标签
        addtime: '', // 视频上传时间
        title: '', //视频标题
        radio: 1,
        showReason: false,
        showReasonInput: false,
        reason: '', // 审核不通过的原因
        selectReason: '',
      },
      reasonOption: [
        {
          value: '视频含有非法内容，上传视频前请先查看上传须知，以确保您的权益',
        },
        {
          value: '视频含有广告内容，上传视频前请先查看上传须知，以确保您的权益',
        },
        { value: '其他' },
      ],
      // 下架
      reasonOption2: [
        { value: '视频含有非法内容，遭多人举报下架' },
        { value: '视频违反法律法规，进行下架' },
        { value: '其他' },
      ],
      dialogArr: {
        id: 0,
        title: '', //标题
        username: '',
        types: [], //分类
        verify: false,
        watch_count: 0, // 观看次数
        goods_count: 0, // 点赞次数
        url: '',
        is_fee:0,
        buy_config:{
          price:0,// "购买金额"
          try_etime:"",// 时间区间选择
          try_btime:"",// 时间区间选择
          free_vips:"", // vip选择
        }
      }, //弹窗编辑数组
      fufeiArr:{
        is_fee:0,
        price:6,// "购买金额"
        try_etime:"",// 时间区间选择
        try_btime:"",// 时间区间选择
        free_vips:"", // vip选择
      },
      videoFlag: false,
      //是否显示进度条
      videoUploadPercent: '',
      //进度条的进度，
      isShowUploadVideo: false,
      //显示上传按钮
      videoForm: {
        showVideoPath: '',
        size: 0, // 上传到后台的大小
        duration: 0, // 上传到后台的秒数
        img_url: '', // 封面图
        file: null,
        filename: '', // 实际存储到数据库的路径
        path: '', //CDN的路径
        pic_url: '', // 实际存储到数据库的封面图
      },
      max_duration: 6 * 60, // 最大时间是6分钟
      max_size: 300 * 1024 * 1024,
      multipleSelection: [],
      curRow: null,
      multiVerifyTitle: '批量审核',
      dialogMultiVerify: false,
      dialogFufei: false,
      upload_video: null,
      per_slice: 1024 * 1024,
      currentVideo: {},
      vipList:[],
    };
  },
  computed: {},
  created() {
    this.getVipList()
    this.getList();
  },
  mounted() {
    this.getType();
  },
  methods: {
    checkIsFee(row){
      if (row.buy_config != ""){
        var buy_config = JSON.parse(row.buy_config)
        if (buy_config.price !== undefined && buy_config.price > 0 && row.is_fee == 0){
          return 0
        }
      }
      return 1
    },
    saveFufei(){
      // 保存付费设置
      if (this.multipleSelection.length == 0){
        this.$message({ message: '请选择视频!', type: 'error' });
        return;
      }
      var ids = [];
      for(var i in this.multipleSelection){
        ids.push(this.multipleSelection[i].id)
      }
      this.$common.showLoading("批量设置付费")
      httpClient("multiFufei").post({
        "ids":ids.join(","),
        "is_fee":this.fufeiArr.is_fee,
        "price":this.fufeiArr.price,
        "try_btime":this.getTime(this.fufeiArr.try_btime),
        "try_etime":this.getTime(this.fufeiArr.try_etime),
      }).then((res)=>{
        if (res.code == 0){
          this.dialogFufei = false
          this.$common.hideLoading()
          if(res.data.errList != undefined && res.data.errList.length > 0){
            this.errList = res.data.errList
            this.dialogFufeiTips = true
          }else{
            this.$message({ message: "设置成功", type: 'success' });
          }
          this.getList()
        }else{
          this.$common.hideLoading()
          this.$message({ message: res.msg, type: 'error' });
        }
      })
    },
   getVipList(){
     // 读取vip列表
     var _this = this
     httpClient("getVipList").post().then((res)=>{
       var vipList = []
       for(const vip of res.data){
         vipList.push({
           label:vip.vip_name,
           value:vip.id+"",
         })
       }
       _this.vipList = vipList
     })
   },
    goToUploader() {
      this.$router.push({ name: '视频上传' });
    },
    goToComment(id) {
      this.$router.push({ name: '评论列表', params: { id: id } });
    },
    goToReport(id) {
      this.$router.push({
        path: 'short_video/report',
        name: '举报列表',
        params: { id: id },
      });
    },
    mutilVerify() {
      if (this.multipleSelection.length == 0) {
        this.$message({ message: '请选择视频!', type: 'error' });
        return;
      }
      var status = 3; // 下架
      if (!this.multiVerify.xiajia) {
        // 审核
        if (this.multiVerify.radio == 0) {
          this.$message({ message: '请选择审核状态!', type: 'error' });
          return;
        }
        status = this.multiVerify.radio;
      }
      if (this.multiVerify.selectReason != '其他') {
        this.multiVerify.reason = this.multiVerify.selectReason;
      }
      if (this.multiVerify.reason == '' && status != 1) {
        this.$message({ message: '请输入原因!', type: 'error' });
        return;
      }
      // 开始审核ids
      verify([
        { key: 'ids', val: this.multiVerify.ids.join(',') },
        { key: 'reason', val: this.multiVerify.reason },
        { key: 'status', val: status },
      ]).then((res) => {
        if (res.code === 0) {
          this.dialogMultiVerify = false;
          for (var i in this.multipleSelection) {
            this.multipleSelection[i].status = status;
            this.multipleSelection[i].reason = this.multiVerify.reason;
          }
        } else {
          this.$message({ message: res.msg, type: 'error' });
        }
      });
    },
    showFufei(){
      if (this.multipleSelection.length == 0) {
        this.$message({ message: '请选择视频!', type: 'error' });
        return;
      }
      this.fufeiArr.try_btime = this.getTime2(0)
      this.fufeiArr.try_etime = this.getTime2(120)
      this.dialogFufei = true;
    },
    showVerify(type) {
      if (this.multipleSelection.length == 0) {
        this.$message({ message: '请选择视频!', type: 'error' });
        return;
      }
      var ids = [];
      for (var index in this.multipleSelection) {
        ids.push(this.multipleSelection[index].id);
      }
      this.multiVerify = {
        xiajia: false,
        radio: 0,
        num: 0,
        showReason: false,
        selectReason: '',
        reason: '',
        ids: ids,
      };
      this.multiVerify.num = this.multipleSelection.length;
      if (type == undefined || type == 0) {
        this.multiVerifyTitle = '批量审核';
      } else {
        this.multiVerifyTitle = '批量下架';
        this.multiVerify.xiajia = true;
        this.multiVerify.showReason = true;
      }
      this.dialogMultiVerify = true;
    },
    verify() {
      // 单个审核
      if (this.verifyArr.radio == 0) {
        this.$message({ message: '请选择审核选项!', type: 'error' });
        return;
      }
      if (this.verifyArr.radio == 2) {
        if (this.verifyArr.selectReason == '') {
          this.$message({ message: '请选择审核不通过的原因!', type: 'error' });
          return;
        }
        if (this.verifyArr.selectReason != '其他') {
          this.verifyArr.reason = this.verifyArr.selectReason;
        }
        if (this.verifyArr.reason == '') {
          this.$message({ message: '请输入审核不通过的原因!', type: 'error' });
          return;
        }
      }
      // 开始审核ids
      verify([
        { key: 'ids', val: this.verifyArr.id },
        { key: 'reason', val: this.verifyArr.reason },
        { key: 'status', val: this.verifyArr.radio },
      ]).then((res) => {
        if (res.code === 0) {
          this.curRow.status = this.verifyArr.radio;
          this.curRow.reason = this.verifyArr.reason;
          this.isShowVideo = false;
        } else {
          this.$message({ message: res.msg, type: 'error' });
        }
      });
    },
    selectReason(value) {
      if (value === '其他') {
        this.showReasonInput = true;
        this.verifyArr.reason = '';
      } else {
        this.verifyArr.reason = value;
        this.showReasonInput = false;
      }
    },
    selectStatus(status) {
      if (status == 2) {
        this.verifyArr.showReason = true;
      } else {
        this.verifyArr.showReason = false;
      }
    },
    closeShowVideo() {
      this.verifyArr = {
        id: 0, // 视频ID
        url: '', // 视频实际地址
        username: '', // 账号
        types: [], //视频标签
        addtime: '', // 视频上传时间
        title: '', //视频标题
        radio: 1,
        status: 0,
        selectReason: '',
        showReason: false,
        showReasonInput: false,
        reason: '',
      };
      this.isShowVideo = false;
      // this.$refs.VideoPlayer.disposeVideo();
    },
    showVideo(row) {
      this.curRow = row;
      let video_url = row.m3u8_url ? row.m3u8_url : row.url;
      this.verifyArr = {
        id: row.id, // 视频ID
        url: this.$store.getVideo( video_url), // 视频实际地址
        username: row.username, // 账号
        types: row.types, //视频标签
        addtime: row.addtime, // 视频上传时间
        title: row.title, //视频标题
        radio: 0,
        status: row.status,
        selectReason: '',
        reason: row.reason,
        showReason: false,
        showReasonInput: false,
      };
      // this.playVideo()
      // this.genVideo('verify_video', row);
      this.currentVideo = row;
      this.isShowVideo = true;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    del() {
      if (this.multipleSelection.length == 0) {
        this.$message({ message: '请选择要删除的短视频!', type: 'error' });
        return;
      }
      var ids = [];
      for (var i in this.multipleSelection) {
        ids.push(this.multipleSelection[i].id);
      }
      this.$confirm('删除ID: ' + ids.join(',') + '吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        del([{ key: 'ids', val: ids.join(',') }]).then((res) => {
          if (res.code == 0) {
            this.getList();
          }
        });
      });
    },
    base64ToFile(urldata, filename) {
      let arr = urldata.split(',');
      let mine = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]); // 解码base64
      let n = bytes.length;
      let ia = new Uint8Array(n);
      while (n--) {
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia], filename, { type: mine });
    },
    getTag(m3u8_tag) {
      if (m3u8_tag == 0) {
        return '国外';
      } else {
        return '国内';
      }
    },
    getDuration(value) {
      let result = parseInt(value);
      let h =
        Math.floor(result / 3600) < 10
          ? '0' + Math.floor(result / 3600)
          : Math.floor(result / 3600);
      let m =
        Math.floor((result / 60) % 60) < 10
          ? '0' + Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60);
      let s =
        Math.floor(result % 60) < 10
          ? '0' + Math.floor(result % 60)
          : Math.floor(result % 60);
      let res = '';
      if (h !== '00') res += `${h}.`;
      if (m !== '00' || h !== '00') res += `${m}.`;
      res += `${s}`;
      return res;
    },
    getSize(size) {
      return (size / this.per_slice).toFixed(2) + 'M';
    },
    getList() {
      var begin_time =
        this.search.searchDate && this.search.searchDate.length > 0
          ? this.search.searchDate[0] + ' 00:00:00'
          : '';
      var end_time =
        this.search.searchDate && this.search.searchDate.length > 0
          ? this.search.searchDate[1] + ' 23:59:59'
          : '';
      list([
        { key: 'page', val: this.pager.page },
        { key: 'row', val: this.pager.rows },
        { key: 'id', val: this.search.id },
        { key: 'types', val: this.search.type_id },
        { key: 'status', val: this.search.status },
        { key: 'username', val: this.search.username },
        { key: 'title', val: this.search.title },
        { key: 'checked', val: this.search.checked },
        { key: 'begin_time', val: begin_time },
        { key: 'end_time', val: end_time },
        { key: 'is_free', val: this.search.is_free },
      ]).then((res) => {
        // console.log(res)
        if (res.code == 0) {
          if (this.pager.page == 1) {
            this.pager.total = res.data.total;
          }
          this.tableData = res.data.list;
        } else {
          this.$message({ message: res.msg, type: 'error' });
        }
      });
    },
    add() {
      // console.log(this.dialogArr)
      if (this.videoFlag) {
        this.$message({ message: '视频正在上传中,请稍等!', type: 'error' });
        return;
      }
      if (this.videoForm.pic_url == '') {
        this.$message({ message: '封面图上传中,请稍等!', type: 'error' });
        return;
      }
      if (parseInt(this.videoForm.duration) > this.max_duration) {
        this.$message({ message: '视频不能超过360秒!', type: 'error' });
        return;
      }
      if (this.videoForm.size > this.max_size) {
        this.$message({ message: '视频不能超过300M!', type: 'error' });
        return;
      }
      add([
        { key: 'title', val: this.dialogArr.title },
        { key: 'username', val: this.dialogArr.username },
        { key: 'url', val: this.videoForm.filename },
        { key: 'pic_url', val: this.videoForm.pic_url },
        { key: 'size', val: this.videoForm.size },
        { key: 'duration', val: parseInt(this.videoForm.duration) },
        { key: 'types', val: this.dialogArr.types.join(',') },
        { key: 'watch_count', val: this.dialogArr.watch_count },
        { key: 'goods_count', val: this.dialogArr.goods_count },
        { key: 'verify', val: this.dialogArr.verify ? 1 : 0 },
      ]).then((res) => {
        if (res.code == 0) {
          this.dialogAdd = false;
          this.$message({ message: '添加成功', type: 'success' });
          this.getList();
        } else {
          this.$message({ message: res.msg, type: 'error' });
        }
      });
    },
    edit() {
      let update = [
        { key: 'id', val: this.dialogArr.id },
        { key: 'title', val: this.dialogArr.title },
        { key: 'url', val: this.videoForm.filename },
        { key: 'pic_url', val: this.videoForm.pic_url },
        { key: 'size', val: this.videoForm.size },
        { key: 'duration', val: parseInt(this.videoForm.duration) },
        { key: 'types', val: this.dialogArr.types.join(',') },
        { key: 'watch_count', val: this.dialogArr.watch_count },
        { key: 'goods_count', val: this.dialogArr.goods_count },
        { key: 'username', val: this.dialogArr.username },
        { key: 'is_fee', val: this.dialogArr.is_fee },
        { key: "buy_config_price", val: this.dialogArr.buy_config.price},
        { key: "buy_config_btime", val: this.getTime(this.dialogArr.buy_config.try_btime)},
        { key: "buy_config_etime", val: this.getTime(this.dialogArr.buy_config.try_etime)},
        // { key: "buy_config_vips", val: this.dialogArr.buy_config.free_vips?this.dialogArr.buy_config.free_vips.join(","):""},
        { key: "buy_config_vips", val: ""},
      ];
      var buy_config = {
        price:this.dialogArr.buy_config.price,
        try_btime:this.getTime(this.dialogArr.buy_config.try_btime),
        try_etime:this.getTime(this.dialogArr.buy_config.try_etime),
        free_vips:""
      }
      if (this.videoFlag) {
        this.$message({ message: '视频正在上传中', type: 'error' });
        return;
      }
      if (this.videoForm.pic_url == '') {
        this.$message({ message: '封面图上传中,请稍等!', type: 'error' });
        return;
      }
      if (parseInt(this.videoForm.duration) > this.max_duration) {
        this.$message({ message: '视频不能超过360秒!', type: 'error' });
        return;
      }
      if (this.videoForm.size > this.max_size) {
        this.$message({ message: '视频不能超过300M!', type: 'error' });
        return;
      }
      if (this.dialogArr.username == '') {
        this.$message({ message: '请输入账号', type: 'error' });
        return;
      }
      edit(update).then((res) => {
        if (res.code == 0) {
          this.dialogAdd = false;
          this.$message({ message: '修改成功', type: 'success' });
          for (const data of update) {
            this.curRow[data.key] = data.val;
          }
          this.curRow.buy_config = JSON.stringify(buy_config)
          var types = [];
          for (const id of this.dialogArr.types) {
            types.push(this.getTypeBy(id));
          }
          this.curRow.types = types;
        } else {
          this.$message({ message: res.msg, type: 'error' });
        }
      });
    },

    getTypeBy(id) {
      for (var i in this.typeList) {
        if (this.typeList[i].id == id) {
          return this.typeList[i];
        }
      }
      return null;
    },
    getType() {
      tagList().then((res) => {
        this.typeList = res.data.list;
      });
    },
    showDialog(row) {
      this.dialogTitle = '视频编辑';
      this.curRow = row;
      var types = [];
      for (var i in row.types) {
        types.push(row.types[i].id);
      }
      var buy_config =  {
        price:0,
        try_btime:0,
        try_etime:0,
        free_vips:""
      }
      // console.log(row.buy_config)
      if (row.buy_config != ""){
        buy_config = JSON.parse(row.buy_config)
        if(buy_config.price === undefined){
          buy_config.price = 0
        }
        if(buy_config.try_btime === undefined){
          buy_config.try_btime = 0
        }
        if(buy_config.try_etime === undefined){
          buy_config.try_etime = 0
        }
        if(buy_config.free_vips === undefined){
          buy_config.free_vips = ""
        }
      }
      // if (buy_config.price === undefined  || buy_config.price === 0){
      //   buy_config.price = 6
      // }
      // if (buy_config.try_etime === undefined || buy_config.try_etime === 0){
      //   buy_config.try_btime = 0
      //   buy_config.try_etime = 120
      // }
      this.dialogArr = {
        id: row.id,
        title: row.title, //标题
        username: row.username,
        types: types, //分类
        verify: false,
        watch_count: row.watch_count, // 观看次数
        goods_count: row.goods_count, // 点赞次数
        url: row.url,
        is_fee:row.is_fee,
        buy_config:{
          price:buy_config.price,
          try_btime:this.getTime2(buy_config.try_btime),
          try_etime:this.getTime2(buy_config.try_etime),
          free_vips:"",
        }
      };
      this.videoFlag = false;
      this.videoForm.img_url = this.$store.getImage(row.pic_url);
      this.videoForm.pic_url = row.pic_url;
      this.videoForm.size = row.size;
      this.videoForm.duration = row.duration;
      this.videoForm.showVideoPath = this.$store.getVideo(row.m3u8_url);
      this.videoForm.filename = row.url;
      this.currentVideo = row;
      // this.genVideo('short-video', row);
      this.dialogAdd = true;
    },
    getTime(t){
      if(t == "" || t == null){
        return 0
      }
      var rows = t.split(":")
      if (rows.length != 3){
        return 0
      }
      return parseInt(rows[0]) * 3600 + parseInt(rows[1]) * 60 + parseInt(rows[2])
    },
    getTime2(t){
      var h = Math.floor(t / 3600)
      var m = Math.floor((t - (h*3600)) / 60)
      var s = t - (h*3600+m*60)
      return h+":"+m+":"+s
    },
    closeVideojs() {
      document.getElementById('short_video').style.display = 'none';
    },
    showVideojs() {
      document.getElementById('short_video').style.display = 'revert';
    },
  },
};
</script>

<style scoped>
.width_80 {
  width: 80px !important;
}
.width_100 {
  width: 100px !important;
}
.width_120 {
  width: 120px !important;
}
.width_150 {
  width: 150px !important;
}
.width_200 {
  width: 200px !important;
}
.width_250 {
  width: 250px !important;
}
.el-row {
  margin-bottom: 20px;
}
.el-row .el-col:first-child {
  text-align: right;
  padding-right: 10px;
  box-sizing: border-box;
}
.upload-video {
  width: 200px;
  height: 200px;
}
/* 图片上传部分 */
.el-row >>> .avatar-uploader .el-upload {
  /*border: 1px dashed #d9d9d9;*/
  /*border-radius: 6px;*/
  /*cursor: pointer;*/
  /*position: relative;*/
  /*overflow: hidden;*/
}
.el-row >>> .avatar-uploader .el-upload:hover {
  /*border-color: #409eff;*/
}
.el-row >>> .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  line-height: 200px;
  text-align: center;
  border: 1px #cccccc dashed;
}
.el-row >>> .avatar {
  width: 200px;
  height: 200px;
  display: block;
}
</style>
<!--
ffmpeg -i /Users/maxiao/IdeaProjects/jycm/backend_vue/src/assets/yuepao.mp4 -c:v libx264 -hls_time 10 -hls_list_size 0 -c:a aac -strict -2 -f hls /Users/maxiao/IdeaProjects/jycm/backend_vue/src/assets/yuepao.m3u8

-->
